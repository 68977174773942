<template>
    <v-section>
        <v-card-content>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                <v-form-group :permissions="['payouts.read']">
                    <v-form-label>Status</v-form-label>
                    <v-form-input :value="payout.payout_status.name" type="text" :readonly="true" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="['payouts.read']">
                    <v-form-label>Created At</v-form-label>
                    <v-form-input :value="payout.created_at" type="text" :readonly="true" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="['payouts.read']">
                    <v-form-label>User</v-form-label>
                    <v-form-input :value="payout.user.name" type="text" :readonly="true" :disabled="isLoading" />
                </v-form-group>

                <v-form-group :permissions="['payouts.read']">
                    <v-form-label>Total Amount</v-form-label>
                    <v-form-input :value="`${$options.filters.numberFormat(payout.total_amount)}`" type="text" :readonly="true" :disabled="isLoading" />
                </v-form-group>

                <v-form-group v-if="payout.user.paypal_email" :permissions="['payouts.read']">
                    <v-form-label>PayPal Email</v-form-label>
                    <v-form-input :value="payout.user.paypal_email" type="text" :readonly="true" :disabled="isLoading" />
                </v-form-group>

                <v-form-group v-if="payout.user.paypal_me" :permissions="['payouts.read']">
                    <v-form-label>PayPal Me Link</v-form-label>
                    <v-link :href="payout.user.paypal_me.startsWith('https://') ? payout.user.paypal_me : `https://${payout.user.paypal_me}`" class="py-2" target="_blank">
                        {{ payout.user.paypal_me }}
                    </v-link>
                </v-form-group>
            </div>
        </v-card-content>

        <v-section-heading>
            <template v-slot:default>
                Payout Lines
            </template>

            <template v-slot:description>
                View the individual jobs for this payout.
            </template>
        </v-section-heading>

        <v-payout-line-table :data-function="getPayoutLines" :query="query" :columns="columns">
            <template v-slot:column_amount="{ row }">
                <v-text>
                    {{ row.amount | numberFormat }}
                </v-text>
            </template>
        </v-payout-line-table>
    </v-section>
</template>

<script>
import PayoutLineTable from "@/components/application/payout/payout-line-table.vue";
import PayoutService from "@/services/modules/payout-service";

export default {
    components: {
        "v-payout-line-table": PayoutLineTable,
    },
    props: {
        payout: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            query: {},
            getPayoutLines: (payload) => PayoutService.getPayoutLines(this.payout.payout_id, payload),
            columns: {
                order_line_id: {
                    label: "Job ID",
                    fullWidth: false,
                    formatter: (row) => row.order_line_id,
                },
                order_id: {
                    label: "Order ID",
                    fullWidth: false,
                    formatter: (row) => row.order_line.order_id,
                },
                source_order_id: {
                    label: "Source Order ID",
                    fullWidth: false,
                    formatter: (row) => row.order.source_order_id,
                },
                product_name: {
                    label: "Product",
                    formatter: (row) => row.product_name,
                },
                amount: {
                    label: "Amount",
                    fullWidth: false,
                    align: "right",
                },
            },
        };
    },
};
</script>
