<template>
    <v-section-group>
        <v-model-table v-bind="tableDataSource" :columns="columns" :query="query">
            <template v-slot:column_order_line_id="{ row, column, columnIndex }">
                <v-link @click="selectPayoutLine(column.formatter ? column.formatter(row) : row[columnIndex])">
                    {{ column.formatter ? column.formatter(row) : row[columnIndex] }}
                </v-link>
            </template>

            <template v-slot:column_order_id="{ row }">
                <v-link :to="{ name: 'orders.show', params: { order: row.order.order_id } }" color="primary">
                    {{ row.order.order_id }}
                </v-link>
            </template>

            <template v-slot:column_amount="{ row }">
                <v-text>
                    {{ row.amount | numberFormat }}
                </v-text>
            </template>
        </v-model-table>

        <v-job-slide-over v-if="selectedPayoutLineId" v-model="jobSlideOver" :job-id="selectedPayoutLineId" />
    </v-section-group>
</template>

<script>
import JobSlideOver from "../job/job-slide-over.vue";

export default {
    name: "PayoutLineTable",
    components: {
        "v-job-slide-over": JobSlideOver,
    },
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
        columns: {
            type: Object,
            required: false,
            default: () => ({
                order_line_id: {
                    label: "Job ID",
                    fullWidth: false,
                    formatter: (row) => row.order_line.order_line_id,
                },
                order_id: {
                    label: "Order ID",
                    fullWidth: false,
                    formatter: (row) => row.order_line.order_id,
                },
                product: {
                    label: "Product",
                    formatter: (row) => row.order_line.product_name,
                },
                amount: {
                    label: "Amount",
                    fullWidth: false,
                    align: "right",
                },
            }),
        },
    },
    data() {
        return {
            selectedPayoutLineId: null,
            jobSlideOver: false,
        };
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "payoutLineModule",
            };
        },
    },
    watch: {
        "$route.query": {
            handler({ jobId, jobSlideOver }) {
                if (!jobId || !jobSlideOver) {
                    this.selectedPayoutLineId = null;
                    this.jobSlideOver = false;
                    return;
                }

                this.selectedPayoutLineId = jobId;
                this.jobSlideOver = true;
            },
            immediate: true,
            deep: true,
        },
        jobSlideOver: {
            handler(value) {
                if (!value) {
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            jobId: undefined,
                            jobSlideOver: undefined,
                        },
                    });
                }
            },
        },
    },
    methods: {
        selectPayoutLine(orderLineId) {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    jobId: orderLineId || undefined,
                    jobSlideOver: orderLineId ? true : undefined,
                },
            });
        },
    },
};
</script>
