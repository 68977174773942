var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-section-group',[_c('v-model-table',_vm._b({attrs:{"columns":_vm.columns,"query":_vm.query},scopedSlots:_vm._u([{key:"column_order_line_id",fn:function(ref){
var row = ref.row;
var column = ref.column;
var columnIndex = ref.columnIndex;
return [_c('v-link',{on:{"click":function($event){_vm.selectPayoutLine(column.formatter ? column.formatter(row) : row[columnIndex])}}},[_vm._v(" "+_vm._s(column.formatter ? column.formatter(row) : row[columnIndex])+" ")])]}},{key:"column_order_id",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'orders.show', params: { order: row.order.order_id } },"color":"primary"}},[_vm._v(" "+_vm._s(row.order.order_id)+" ")])]}},{key:"column_amount",fn:function(ref){
var row = ref.row;
return [_c('v-text',[_vm._v(" "+_vm._s(_vm._f("numberFormat")(row.amount))+" ")])]}}])},'v-model-table',_vm.tableDataSource,false)),(_vm.selectedPayoutLineId)?_c('v-job-slide-over',{attrs:{"job-id":_vm.selectedPayoutLineId},model:{value:(_vm.jobSlideOver),callback:function ($$v) {_vm.jobSlideOver=$$v},expression:"jobSlideOver"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }